require('./src/fonts/fonts.css');

const React = require('react');
const { GoogleReCaptchaProvider } = require('react-google-recaptcha-v3');
const GlobalContextProvider = require('./src/context/GlobalContextProvider').default;

exports.wrapRootElement = ({ element }) => {
	return (
		<GlobalContextProvider>
			<GoogleReCaptchaProvider reCaptchaKey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}>
				{element}
			</GoogleReCaptchaProvider>
		</GlobalContextProvider>
	);
};
