import React, { createContext, useReducer } from 'react';

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
	cursorVariant: 'default',
};

function reducer(state, action) {
	switch (action.type) {
		case 'DEFAULT':
			return {
				...state,
				cursorVariant: 'default',
			};
		case 'DEFAULT_WHITE':
			return {
				...state,
				cursorVariant: 'defaultWhite',
			};
		case 'LOGO':
			return {
				...state,
				cursorVariant: 'logo',
			};
		case 'SERVICES':
			return {
				...state,
				cursorVariant: 'services',
			};
		case 'CASES':
			return {
				...state,
				cursorVariant: 'cases',
			};
		default:
			throw new Error('Bad Action Type');
	}
}

const GlobalContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	);
};

export default GlobalContextProvider;
