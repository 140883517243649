exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-changing-face-of-dx-js": () => import("./../../../src/pages/changing-face-of-dx.js" /* webpackChunkName: "component---src-pages-changing-face-of-dx-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-from-likes-to-leads-js": () => import("./../../../src/pages/from-likes-to-leads.js" /* webpackChunkName: "component---src-pages-from-likes-to-leads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-master-service-terms-conditions-group-js": () => import("./../../../src/pages/master-service-terms-conditions-group.js" /* webpackChunkName: "component---src-pages-master-service-terms-conditions-group-js" */),
  "component---src-pages-master-service-terms-conditions-js": () => import("./../../../src/pages/master-service-terms-conditions.js" /* webpackChunkName: "component---src-pages-master-service-terms-conditions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-article-child-js": () => import("./../../../src/templates/article-child.js" /* webpackChunkName: "component---src-templates-article-child-js" */),
  "component---src-templates-services-child-js": () => import("./../../../src/templates/services-child.js" /* webpackChunkName: "component---src-templates-services-child-js" */),
  "component---src-templates-social-media-marketing-js": () => import("./../../../src/templates/social-media-marketing.js" /* webpackChunkName: "component---src-templates-social-media-marketing-js" */),
  "component---src-templates-solutions-page-js": () => import("./../../../src/templates/solutions-page.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */),
  "component---src-templates-subservices-page-js": () => import("./../../../src/templates/subservices-page.js" /* webpackChunkName: "component---src-templates-subservices-page-js" */),
  "component---src-templates-work-child-js": () => import("./../../../src/templates/work-child.js" /* webpackChunkName: "component---src-templates-work-child-js" */)
}

